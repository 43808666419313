




import React from "react";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import Navbar from "../../settings2/src/Navbar.web";
import CalenderController, { BUTTON_DAY_PRESSED, COLOR_TYPE, CalendarType, ItemEventType, Props, TYPE_CALENDAR } from "./CalenderController.web";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import moment from "moment";
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import View from "@fullcalendar/core/View";
import styled from "@emotion/styled";
import { EventApi } from "@fullcalendar/core";
import { DateInput } from "@fullcalendar/core/datelib/env";
import AddEventModal from "./AddEventModal.web";
import { addIcon, deleteIcon, editIcon } from "./assets";
import { Alert } from "@material-ui/lab";
import { Grid } from "@material-ui/core";
import Strings from "./Strings";
import "./Calender.css";
import "moment/min/locales";

const StyledComponentTd = styled.td`
  .fc th, .fc td {
    border-color: #e0e0e0;
  }
  .fc-widget-header {
    border-width: 0
  }
`
const StyledComponentSpan = styled.span`
  .fc-content {
    height: 0;
    opacity: 0;
  }
  .fc-time {
    color: #71717A;
    font-family: Barlow Semi Condensed;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.386px;
    background-color: rgba(0, 0, 0, 0.0);
    border-width: 0;
  }
`
const StyledComponentDiv = styled.div`
  padding-right: 8px;

  .fc-time-grid-event-inset {
    box-shadow: 0px 0px 0px 0px #fff;
  }
  .fc-time-grid .fc-slats td {
    height: 60px;
  }
  .fc-day fc-widget-content fc-today {
    height: 0;
    opacity: 0;
    background-color: white;
    border-bottom-width: 4px;
    border-bottom-color: rgba(0, 0, 0, 0.0);
  }
  .fc-day-number {
    color: rgba(0, 0, 0, 0.0);
  }
  .fc-event {
    line-height: 1;
  }
`
const StyledComponentButton = styled.button`
  background-color: rgba(0,0,0,0.0);
  .fc-today-button {
    border: 0px;
    color: #18181B;
    background-color: rgba(0,0,0,0.0);
    font-family: Barlow Semi Condensed;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.057px;
    margin: 0px 8px;
  }
  .fc-button {
    padding: 1px 1px;
    border-color: #E2E8F0;
  }
  .fc-button:disabled {
    opacity: 1
  }
  .fc-button .fc-icon {
    font-size: 16px;
    color: #1C3045;
    padding: 5px 5px;
  }
  .fc-prev-button {
    background-color: #E2E8F0;
    margin-right: 8px;
  }
  .fc-icon-chevron-left{
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .fc-next-button {
    background-color: #E2E8F0;
  }
  .fc-icon-chevron-right{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .fc-button-active {
    padding: 3px 12px
  }
  .fc-button-primary:not(:disabled):active, .fc-button-primary:not(:disabled).fc-button-active {
    background-color: #DC2626;
    border-radius: 6px;
    border-width: 0px;
    color: #FFF;
    font-family: Barlow Semi Condensed;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    letter-spacing: 0.057px;
  }
  .fc-timeGridWeek-button {
    padding: 3px 12px;
    color: #71717A;
    border-width: 0px;
    background-color: rgba(0,0,0,0.0);
    font-family: Barlow Semi Condensed;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.386px;
  }
  .fc-timeGridDay-button {
    padding: 3px 12px;
    background-color: rgba(0,0,0,0.0);
    color: #71717A;
    border-width: 0px;
    font-family: Barlow Semi Condensed;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.386px;
  }
  .fc-dayGridMonth-button {
    padding: 3px 12px;
    background-color: rgba(0,0,0,0.0);
    color: #71717A;
    border-width: 0px;
    font-family: Barlow Semi Condensed;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18.386px;
  }
  .fc-button-primary:not(:disabled):active:focus, .fc-button-primary:not(:disabled).fc-button-active:focus, .fc-button-primary:focus {
    box-shadow: none;
  }
`

const listNote = [
  {
    text: Strings.contractEndingText,
    icon: COLOR_TYPE.GREEN_BFCC33
  },
  {
    text: Strings.reportDueText,
    icon: COLOR_TYPE.PURPLE
  },
  {
    text: Strings.paymentDueText,
    icon: COLOR_TYPE.RED
  }
]

export default class Calender extends CalenderController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderItemEventNotes(listData: Array<any>, showDate: boolean) {
    if (listData.length > 0) {
      return listData.map((item: any, index: number) => (
        <div style={styles.eventViewNote} key={`today_${index}`}>
          <p style={{
            fontSize: "22px",
            marginRight: "6px",
            color: item?.attributes?.color
          }}>•</p>
          <div style={{ width: "100%", display: 'flex', flexDirection: 'column' }}>
            {showDate && <p style={styles.eventNoteTime}>{moment(new Date(item?.attributes?.event_date)).locale(this.state.language).format("DD/MM/YYYY")}</p>}
            <p style={styles.eventNoteText}>{item?.attributes?.title ?? ""}</p>
          </div>
        </div>
      ))
    } else return null
  }

  renderViewReminder() {
    const todayDate = new Date().toLocaleDateString('en-GB')
    let nextDayDate = new Date()
    nextDayDate.setDate(new Date().getDate() + 1)
    const tomorrow = nextDayDate.toLocaleDateString('en-gb')
    return (
      <Grid item xs={12} sm={2} style={styles.containerViewReminder} classes={{root:"calender-view-reminder-container",item:"calender-view-reminder-item"}}>
        <div style={styles.headerReminder}>{Strings.reminderText}</div>
        <div style={{ marginBottom: 12 }}>
          {
            listNote.map((item: { text: string; icon: string }) => (
              <div style={styles.rowItemNote} key={item.text}>
                <p style={{
                  fontSize: "22px",
                  marginTop: "2px",
                  marginRight: "6px",
                  color: item.icon
                }}>•</p>
                <p style={styles.textNote}>{item.text}</p>
              </div>
            ))
          }
        </div>
        <div style={styles.viewDateReminder}>
          <p style={styles.dayText}>{Strings.todayText}</p>
          <p style={styles.dateText}>{todayDate}</p>
        </div>
        {this.renderItemEventNotes(this.state.todayEvent, false)}
        {
          this.state.tomorrowEvent.length > 0 && (
            <div style={styles.viewDateReminder}>
              <p style={styles.dayText}>{Strings.tomorrowText}</p>
              <p style={styles.dateText}>{tomorrow}</p>
            </div>
          )
        }
        {this.renderItemEventNotes(this.state.tomorrowEvent, false)}
        {
          this.state.upcomingEvent.length > 0 && (
            <div style={styles.viewDateReminder}>
              <p style={styles.dayText}>{Strings.upcomingText}</p>
            </div>
          )
        }
        {this.renderItemEventNotes(this.state.upcomingEvent, true)}
      </Grid>
    )
  }

  renderViewHeader() {
    return (
      <div style={styles.headerCalendar}>
        <div style={styles.headerText}>{Strings.calendar}</div>
        {
          this.state.isEventChanged && <Alert icon={false} style={styles.alert_style} >
            {Strings.eventChanged}
          </Alert>
        }
        <div style={styles.buttonAdd} data-test-id="addModal" onClick={() => this.setState({ modalVisible: true })}>
          <img src={addIcon} style={styles.plusIcon} />
          <p style={styles.addText}>{Strings.addText}</p>
        </div>
      </div>
    )
  }

  returnCellDate(dayOfWeekText: string, date: string) {
    return `<div style='align-items:flex-start;display:flex;flex-direction:column'><span style='font-size:14px;font-weight:400;color:#71717A;font-family:Barlow Semi Condensed;line-height:18px'>${dayOfWeekText}</span><span style='font-size:16px;color:#1c3045;font-family:Barlow Semi Condensed;line-height:18px;font-weight:${this.state.currentCalendarType === CalendarType.MONTH ? "500" : "700"}'>${date}</span></div>`
  }

  // istanbul ignore next
  returnCellContent(info: { view: View, date: Date, el: HTMLElement }) {
    const dayOfWeekNumber = info.date.getDay()
    if (this.state.currentCalendarType === CalendarType.MONTH) {
      const dayOfWeekText = moment(info.date).locale(this.state.language).format('ddd').toUpperCase();
      const date = moment(info.date).locale(this.state.language).format("DD");

      const startDate = info.view.activeStart
      let nextWeekDate = startDate
      nextWeekDate.setDate(startDate.getDate() + 7)
      if (info.date < nextWeekDate) {
        info.el.innerHTML += this.returnCellDate(dayOfWeekText, date);
      } else {
        info.el.style.paddingTop = "12px"
        info.el.innerHTML += `<span style='margin-top:20px;font-size:16px;color:#1c3045;font-family:Barlow Semi Condensed;line-height:18px;font-weight:500'>${date}</span>`;
      }

      if (dayOfWeekNumber === 0 || dayOfWeekNumber === 6) {
        info.el.style.backgroundColor = '#fafafa';
      } else {
        info.el.style.backgroundColor = '#fff';
      }
      if (this.checkEventsDay(info.date) && this.state.events.length > 0) {
        info.el.style.backgroundColor = '#E1F0E6';
      }
      info.el.style.paddingLeft = "4px"
      info.el.style.paddingRight = "4px"
    } else {
      info.el.style.backgroundColor = '#fff';
      if (this.checkEventsDay(info.date) && this.state.events.length > 0) {
        info.el.style.backgroundColor = '#E1F0E6';
      }
    }
  }

  // istanbul ignore next
  returnEventContent(events: {
    event: EventApi;
    el: HTMLElement;
  }) {
    const title = events.event.title
    const color = events.event.textColor
    if (this.state.currentCalendarType === CalendarType.MONTH) {
      events.el.innerHTML += `<div style='display:flex;flex-direction:row;align-items:center'><p style='font-size:20px;margin-top:4px;margin-right:8px;color:${color}'>•</p><span style='font-size:16px;color:${color};font-family:Barlow Semi Condensed;line-height:24px;font-weight:600;text-overflow:ellipsis;overflow:hidden;white-space:nowrap'>${title}</span></div>`;
    } else {
      events.el.innerHTML += `<div style='width:100%;display:flex;flex-direction:column;height:100%;;align-items:center;background-color:#E1F0E6'><span style='font-size:16px;color:${color};font-family:Barlow Semi Condensed;width:100%;line-height:24px;font-weight:600;text-overflow:ellipsis;overflow:hidden;-webkit-line-clamp:1;display:-webkit-box;-webkit-box-orient:vertical;'>${title}</span></div>`;
    }
  }

  // istanbul ignore next
  handleEventClick(event: {
    el: HTMLElement;
    event: EventApi;
    jsEvent: MouseEvent;
    view: View;
  }) {
    // handleEventClick(event: any) {
    // event.jsEvent.preventDefault();
    const item = this.state.eventList.find((item) => item.id === event.event.id)
    const left = event.el.getBoundingClientRect().left
    const top = event.el.getBoundingClientRect().top
    this.setState({
      top: top + 25,
      left: left,
      modalItemVisible: true,
      currentItem: item
    })
    if (typeof window != 'undefined' && window.document) {
      document.body.style.overflow = 'hidden';
    }
  }

  renderHeaderTable(date: any) {
    const dayOfWeekText = moment(date).locale(this.state.language).format('ddd').toUpperCase();
    const dateNumber = date.getDate().toString();
    let backgroundColor = "#fff"
    if (date.getMonth() === new Date().getMonth() && date.getDate() === new Date().getDate() && date.getFullYear() === new Date().getFullYear()) {
      backgroundColor = '#34D399';
    }
    const typeEvent = this.getEventTypeExistOnDay(date)
    let htmlEventType = "<div style='width:100%;display:flex;flex-wrap:wrap;'>"
    if (typeEvent.length > 0) {
      typeEvent.map((item: string) => {
        htmlEventType += `<p style='font-size:16px;margin-left:2px;margin-top:2px;color:${item}'>•</p>`
      })
    }
    htmlEventType += "</div>"
    return `<div style='${this.state.currentCalendarType === CalendarType.DAY ? "width:110px;" : ""}border:0.2px solid #e0e0e0;border-bottom-width:0;height:60px;display:flex;padding-left:6px;padding-right:6px;background-color:${backgroundColor};flex-direction:row;'>${this.returnCellDate(dayOfWeekText, dateNumber)}${htmlEventType}</div>`
  }

  // istanbul ignore next
  renderEventPosition({ event, el }: any) {
    let row: any = document.getElementById(".fc-slats tr:contains('" + moment(event.start).locale(this.state.language).format('ha') + "')")
    if (row !== null) {
      if (moment(event.start).locale(this.state.language).format('mm') != '00') {
        row = row?.nextElementSibling;
      }
      row.style.height = el.style.height + row?.style.height
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      //Merge Engine DefaultContainer
      <div id="element" className="main-wrapper">
        <NavigationMenu navigation={this.props.navigation} id={""} />
        <div style={{ backgroundColor: "#f6f7fa" }} className="pickup_container_wrapper">
          <div className="pickup_navbar_container">
            <Navbar navigation={this.props.navigation} id={""} />
          </div>
          {this.renderViewHeader()}
          <Grid container xs={12} style={styles.mainView}>
            <div style={styles.viewMonth}>
              <span className="fc-icon fc-icon-chevron-left" data-test-id="prevArrow" onClick={() => this.handleChangeDay(BUTTON_DAY_PRESSED.PREV)} style={styles.nextButton} />
              <p style={styles.timeText}>{moment(this.state.currentDate).locale(this.state.language).format("MMMM YYYY")}</p>
              <span className="fc-icon fc-icon-chevron-right" data-test-id="nextArrow" onClick={() => this.handleChangeDay(BUTTON_DAY_PRESSED.NEXT)} style={styles.nextButton} />
            </div>
            <Grid item xs={12} sm={10} style={{ marginBottom: "12px" }}>
              <StyledComponentDiv>
                <StyledComponentSpan>
                  <StyledComponentButton>
                    <StyledComponentTd>
                      <FullCalendar
                        ref={(ref) => {
                          this.calendarRef = ref
                        }}
                        plugins={[dayGridPlugin, timeGridPlugin]}
                        header={{
                          left: `prev,today,next`,
                          center: '',
                          right: `${TYPE_CALENDAR.MONTH},${TYPE_CALENDAR.WEEK},${TYPE_CALENDAR.DAY}`
                        }}
                        defaultView={TYPE_CALENDAR.MONTH}
                        events={this.state.events}
                        editable={true}
                        selectable={true}
                        selectMirror={true}
                        eventLimit={4}
                        droppable={false}
                        contentHeight={"auto"}
                        height={1100}
                        fixedWeekCount={false}
                        columnHeader={this.state.currentCalendarType !== CalendarType.MONTH}
                        dayRender={(info) => this.returnCellContent(info)}
                        eventRender={(events) => this.returnEventContent(events)}
                        eventBackgroundColor="rgba(0,0,0,0)"
                        eventBorderColor="rgba(0,0,0,0)"
                        eventClick={(event) => this.handleEventClick(event)}
                        columnHeaderHtml={(date: DateInput) => this.renderHeaderTable(date)}
                        eventPositioned={({ event, el }) => this.renderEventPosition({ event, el })}
                        allDaySlot={false}
                        customButtons={{
                          "dayGridMonth": {
                            text: Strings.monthText,
                            click: () => {
                              this.handleClickTypeCalendar(CalendarType.MONTH)
                            }
                          },
                          "timeGridWeek": {
                            text: Strings.weekText,
                            click: () => {
                              this.handleClickTypeCalendar(CalendarType.WEEK)
                            }
                          },
                          "timeGridDay": {
                            text: Strings.dayText,
                            click: () => {
                              this.handleClickTypeCalendar(CalendarType.DAY)
                            }
                          },
                          "today": {
                            text: this.returnTitleHeader(),
                            click: () => {
                              this.handleChangeDay(BUTTON_DAY_PRESSED.TODAY)
                            }
                          },
                          "prev": {
                            text: "<",
                            click: () => {
                              this.handleChangeDay(BUTTON_DAY_PRESSED.PREV)
                            }
                          },
                          "next": {
                            text: ">",
                            click: () => {
                              this.handleChangeDay(BUTTON_DAY_PRESSED.NEXT)
                            }
                          }
                        }}
                        slotDuration={"01:00:00"}
                        eventDurationEditable={false}
                        nowIndicator={false}
                        minTime={"07:00:00"}
                        maxTime={"18:00:00"}
                      />
                    </StyledComponentTd>
                  </StyledComponentButton>
                </StyledComponentSpan>
              </StyledComponentDiv>
            </Grid>
            {this.renderViewReminder()}
          </Grid>
          <AddEventModal
            testId="modal"
            isOpen={this.state.modalVisible}
            onSaveHandle={(data, id) => this.onSaveHandle(data, id)}
            onDeleteHandle={(id) => this.deleteEvent(id)}
            onPressClose={() => this.setState({ modalVisible: false, currentItem: undefined, isDeleteItem: false })}
            currentItem={this.state.currentItem}
            isDelete={this.state.isDeleteItem}
          />
          <ViewItemModal
            testId="ViewItemModal"
            language={this.state.language}
            isVisible={this.state.modalItemVisible}
            top={this.state.top}
            left={this.state.left}
            item={this.state.currentItem}
            onClickEdit={(item) => {
              this.setState({ modalItemVisible: false, modalVisible: true, currentItem: item })
              document.body.style.overflow = 'unset';
            }}
            onClickDelete={(item) => {
              this.setState({ modalItemVisible: false, modalVisible: true, currentItem: item, isDeleteItem: true })
              document.body.style.overflow = 'unset';
            }}
            onclose={() => {
              this.setState({ modalItemVisible: false, currentItem: undefined })
              document.body.style.overflow = 'unset';
            }}
          />
        </div>
      </div>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

export const ViewItemModal = ({
  testId,
  isVisible,
  top,
  left,
  item,
  onClickEdit,
  onClickDelete,
  onclose,
  language='en'
}: {
  testId: string
  isVisible: boolean
  top: number
  left: number
  item?: ItemEventType
  onClickEdit: (item: ItemEventType) => void
  onClickDelete: (item: ItemEventType) => void
  onclose: () => void
  language:'en' | 'ar';
}) => {
  if (isVisible && item) {
    return (
      <div style={styles.viewPopUp}>
        <div
          onClick={() => onclose()}
          style={styles.viewBackground}
          data-test-id="modalItemClose"
        />
        <div style={{
          ...{
            top, left
          },
          ...styles.viewModalItem
        }}>
          <div style={styles.viewTitle}>
            <p style={{ ...styles.titleModalText, ...{ color: item.attributes.color } }}>{item.attributes.title}</p>
            {
              item.attributes.creation_type !== "auto" && (
                <div style={styles.viewIcon}>
                  <img data-test-id="editIcon" src={editIcon} style={styles.icon} onClick={() => onClickEdit(item)} />
                  <img data-test-id="deleteIcon" src={deleteIcon} style={styles.icon} onClick={() => onClickDelete(item)} />
                </div>
              )
            }
          </div>
          <p style={styles.dateModalText}>{moment(new Date(item.attributes.event_date)).locale(language).format("dddd, MMMM Do")}</p>
          <p style={styles.dateModalText}>{item.attributes.description}</p>
        </div>
      </div>
    )
  } else return <div />
}

// Customizable Area Start

const styles = {
  viewBackground: {
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.0)",
  },
  alert_style: {
    color: '#288700',
    fontFamily: 'Barlow Semi Condensed',
    fontSize: '16px',
    fontWeight: 400,
    backgroundColor: '#DCFBCF',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    lineHeight: "24px",
    padding: "4px 12px 4px 12px",
    minWidth: "40%"
  } as const,
  viewPopUp: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000
  } as any,
  cell: {
    position: "absolute",
    top: 0,
    left: 0,
    color: "#f3f4f5"
  },
  viewTitle: {
    width: "100%",
    display: 'flex',
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "24px"
  } as any,
  viewIcon: {
    display: 'flex',
    flexDirection: "row",
    gap: "24px"
  } as any,
  viewModalItem: {
    backgroundColor: "#fff",
    position: 'absolute',
    zIndex: 100,
    borderRadius: "8px",
    boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.10), 4px 0px 10px 0px rgba(0, 0, 0, 0.10)",
    padding: "24px",
    paddingBottom: "16px",
    display: "flex",
    flexDirection: "column",
    width: "300px"
  } as any,
  titleModalText: {
    color: "#32569A",
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "24px",
    fontFamily: "Barlow Semi Condensed",
  } as any,
  dateModalText: {
    color: "#1C3045",
    fontSize: "14px",
    fontFamily: "Barlow Semi Condensed",
    marginBottom: "8px"
  } as any,
  icon: {
    width: "20px",
    height: "20px",
  },
  mainView: {
    position: 'relative',
    paddingLeft: "24px",
    paddingRight: "32px",
    paddingBottom: "16px",
  } as any,
  containerViewReminder: {
    borderRadius: "8px",
    backgroundColor: "#E1F0E6",
    padding: "12px",
    overflow: "hidden",
    overflowY: "auto",
  } as any,
  headerReminder: {
    color: "#1C3045",
    fontSize: "20px",
    fontWeight: "700",
    lineHeight: "24px",
    fontFamily: "Barlow Semi Condensed",
    marginBottom: "12px"
  } as any,
  rowItemNote: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  } as any,
  iconNote: {
    width: "10px",
    height: "10px",
    marginRight: "24px"
  },
  textNote: {
    color: "#000",
    fontFamily: "Barlow Semi Condensed",
    fontSize: "14px",
    lineHeight: "24px",
  } as any,
  viewDateReminder: {
    backgroundColor: "#fff",
    borderRadius: 8,
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "calc(100% - 24px)",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 2,
    paddingBottom: 2,
    marginTop: "8px"
  } as any,
  dayText: {
    color: "#32569A",
    fontFamily: "Barlow Semi Condensed",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
  } as any,
  dateText: {
    color: "#32569A",
    fontFamily: "Barlow Semi Condensed",
    fontSize: "14px",
    lineHeight: "24px",
    marginLeft: "8px"
  },
  eventNoteText: {
    color: "#1C3045",
    fontFamily: "Barlow Semi Condensed",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "24px",
  } as any,
  timeNoteText: {
    color: "rgba(28, 48, 69, 0.50)",
    fontFamily: "Barlow Semi Condensed",
    fontSize: "12px",
    lineHeight: "24px",
  } as any,
  eventViewNote: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 6
  } as any,
  iconNoteEvent: {
    width: 10,
    height: 10,
    marginRight: 6,
    marginTop: 8
  },
  addText: {
    color: "#fff",
    fontFamily: "Barlow Semi Condensed",
    fontSize: "16px",
    fontWeight: 600
  },
  plusIcon: {
    width: "14px",
    height: "14px",
    marginRight: 8
  },
  buttonAdd: {
    display: "flex",
    flexDirection: "row",
    padding: "12px 16px",
    backgroundColor: "#32569a",
    alignItems: "center",
    borderRadius: 8
  } as any,
  headerCalendar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "calc(100% - 60px)",
    marginLeft: 30,
    marginBottom: 20,
    marginTop: 32
  } as any,
  headerText: {
    color: "#1C3045",
    fontFamily: "Barlow Semi Condensed",
    fontSize: "24px",
    lineHeight: "24px",
    fontWeight: 700,
  },
  eventNoteTime: {
    color: "rgba(28, 48, 69, 0.50)",
    fontFamily: "Barlow Semi Condensed",
    fontSize: "12px",
    lineHeight: "24px",
  },
  timeText: {
    color: "#1C3045",
    fontFamily: "Barlow Semi Condensed",
    fontSize: "20px",
    lineHeight: "18px",
    fontWeight: "500",
  } as any,
  viewMonth: {
    display: "flex",
    position: "absolute",
    top: "0px",
    zIndex: 10,
    flexDirection: "row",
    alignItems: "center",
    left: "190px",
  } as any,
  nextButton: {
    fontSize: "22px",
    width: "28px",
    height: "28px",
    display: 'flex',
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer"
  } as any,
};
// Customizable Area End
