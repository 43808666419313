import React from "react";
import WasteBillTableController, { Props } from './WasteTableController.web';
import "../../user-profile-basic/src/client-listing/activeClientTable.css"
import { actionIcon, checkIcon, uncheckIcon, paginationLeftIcon, paginationRightIcon, noResults } from "../src/assets";
import ClientTableSkeletonLoader from "../../user-profile-basic/src/client-listing/ClientTableSkeletonLoader";
import moment from "moment";
import Strings from "./Strings";

export default class WasteTable extends WasteBillTableController {


  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  getGoBackButton()
  {
    if(this.props.searchQueryState.searchText.trim())
    {
      return (
           <button data-test-id="clear_filter" className="clear_filter_btn" style={{marginRight : '3rem'}} onClick={() => this.clearSearch()}>{Strings.goBack}</button>
      )
    }
    return null
  }

  getNoResultsMessage()
  {
    if(this.props.searchQueryState.searchText.trim())
    {
      return (
         Strings.noResultsFound
      )
    }
    return Strings.nothingToShowYet
  }

  noResultScreen()
  {
    return (
      <div style={webStyles.noResultContainer}>
        <img src={noResults} style={webStyles.imageStyle}></img>
        <div style={webStyles.text}>{this.getNoResultsMessage()}</div>
        {this.getGoBackButton() }
        </div>
        
    )
  }


  WasteRenderBlock = () => {
    if (this.props.loader) {
      return <ClientTableSkeletonLoader />
    }
    if (this.props.clientListigData.length) {
      return (
        this.props.clientListigData.map((client: any, index: number) => {
          const options: any = { year: 'numeric', month: 'short', day: 'numeric' };
          let inputDate = client.attributes.created_at;
          const formattedDate = new Date(inputDate).toLocaleDateString('en-US', options);
          return (
            <div className="client_table_tr_container wastebill-table-tr-container" key={index}>
              {/* name */}
              <div className="client_table_header_field_container header_field_name_container">
                <img
                  src={
                    this.props.uncheckIcon?.includes(client.attributes.id) ?
                      checkIcon : uncheckIcon
                  }
                  alt="Checkbox"
                  className="client_table_name_filed_checkbox_img"
                  onClick={() => this.props.OnSelectItems(client.attributes.id)}
                />
                <p className="client_table_field_text client_table_field_text_name">{client.attributes.account_company_name}</p>
              </div>
              {/* branch Name*/}
              <div className="client_table_header_field_container header_field_email_container">
                <p className="client_table_field_text_td">{client.attributes.branch_name}</p>
              </div>
              {/* pick up data */}
              <div className="client_table_header_field_container header_field_lastpickup_container">
                <p className="client_table_field_text_td">{client.attributes.report_date ? moment(client.attributes.report_date, "DD/MM/yyyy").format('DD MMM yyyy') : '-'}</p>
              </div>

              {/*Waste Branch Name*/}
              <div className="client_table_header_field_container header_field_city_container">
                <p className="client_table_field_text_td">{client.attributes.recyclable_net_weight}</p>
              </div>

              {/* action for update and edit data */}
              <div className="client_table_header_field_container header_field_action_container">

                <img
                  src={actionIcon}
                  alt="Action"
                  className="client_listing_action_img"
                  data-test-id={`action-${index}`}
                  onClick={(e) => this.onActionClick(e, client.id)}

                />
                {/* option list */}
                {this.state.menuIndex === client.id && (

                  <div className="client_listing_action_menu_container_waste" data-test-id={`actionConsole-${index}`}>
                    <p className="client_listing_action_menu_item" data-test-id={`viewButton-${index}`} onClick={() => this.view(client.id)}>{Strings.view}</p>
                    {/* Edit */}
                    <p className="client_listing_action_menu_item" data-test-id={`editMenu-${index}`}
                      onClick={() => this.edit(client.id)}>
                     {Strings.edit}
                    </p>
                    {/* Delete */}
                    <p className="client_listing_action_menu_item"
                      onClick={() => this.props.onDelete(client.attributes.id)}>
                      {Strings.delete}
                    </p>
                  </div>

                )}
              </div>
            </div>
          )
        })
      )
    }

    return (

      <div className="client_table_tr_container" data-test-id="message_container">
        {/* Error message */}
        <div className="client_table_header_field_container header_field_emptymessage_container">
          <p className="client_table_field_text client_table_field_text_name">Accounts data does not exist</p>
        </div>
      </div>

    )
  }


  // Customizable Area End

  render() {
    // Customizable Area Start
    let { total_count, total_page, page, activePagiantionStep, per_page } = this.props.searchQueryState

    return (
      this.props.clientListigData.length ? 
      <div className="client_table_wrapper wastebill-table-wrapper">
        <div className="client_table_conttainer" style={{borderRadius : '12px'}}>
          {/* header */}
          <div className="client_table_header_container wastebill-table-header-container">
            <div className="client_table_header_field_container header_field_name_container">
              <img
                src={this.state.headerCheck && this.props.uncheckIcon.length > 0 ? checkIcon : uncheckIcon}
                alt="Checkbox"
                className="client_table_name_filed_checkbox_img"
                data-test-id = "checkBox"
                onClick={this.handleCheck}
              />
              <p className="client_table_field_text">{Strings.name}</p>
            </div>

            <div className="client_table_header_field_container header_field_email_container">
              <p className="client_table_field_text">{Strings.branch}</p>
            </div>

            <div className="client_table_header_field_container header_field_lastpickup_container">
              <p className="client_table_field_text">{Strings.pickupDate}</p>
            </div>

            <div className="client_table_header_field_container header_field_city_container">
              <p className="client_table_field_text">{Strings.amountOfWaste}</p>
            </div>

            <div className="client_table_header_field_container header_field_action_container">
              <p className="client_table_field_text">{Strings.action}</p>
            </div>
          </div>


          {this.WasteRenderBlock()}
          </div>

        <div className="client_pagination_container">
          <div className="client_pagination_count_text" style={{color : "#667085"}}>{Strings.showing} {(page - 1) * (per_page) + 1}-{page === total_page ? total_count : page * per_page} {Strings.From} {total_count}</div>
          {/* buttons for pagination  */}
          <div className="client_pagination_button_container">

            <div data-test-id="previewPaginationButton" className="client_pagination_button"
              onClick={this.downPage}>
              <img src={paginationLeftIcon} alt="left_icon" />
            </div>

            {Array.from(Array(total_page).keys()).slice(((activePagiantionStep - 1) * 5),
              ((activePagiantionStep - 1) * 5) + 5).map((pageNumber: any, pageIndex: number) => {
                return <div data-test-id={`page-button-${pageIndex + 1}`} className={`client_pagination_button ${page === ((activePagiantionStep - 1) * 5) + (pageIndex + 1) && 'client_pagination_active_button'} `} key={pageIndex} onClick={this.pageChange(((activePagiantionStep - 1) * 5) + pageIndex + 1)}>{((activePagiantionStep - 1) * 5) + pageIndex + 1}</div>
              })}
            {
              Math.ceil(total_page / 5) > activePagiantionStep &&
              <div className="client_pagination_button more_button_page">...</div>
            }
            <div data-test-id="nextPaginationButton" className="client_pagination_button"
              onClick={this.upPage}>
              <img src={paginationRightIcon} alt="left_icon" />
            </div>

          </div>
        </div>
      </div>
      :
      this.noResultScreen()
    );
  }
}

const webStyles = {
  noResultContainer : {
    display : 'flex',
    flexFlow : 'column',
    justifyContent : 'center',
    alignItems : 'center',
    width: '100%'
  },
  text : {
    color: '#000',
    fontFamily: 'Barlow Semi Condensed',
    fontSize: '1.25rem',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '1.5rem', // or '120%'
    marginTop : '2rem',
    marginRight : '3rem'
  },
  imageStyle : {
    width : '394px',
    height : '320px'
  }
}