Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getUserListApiContentType = "application/json";
exports.getUserListApiMethod = "GET";
exports.getUserListApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.addUserApiMethod = "POST";
exports.addUserApiEndPoint = "/bx_block_custom_forms/custom_forms";

exports.editUserApiMethod = "PUT";
exports.editUserApiEndPoint = "/bx_block_custom_forms/custom_forms";
exports.endPointApiGetWasteBill="waste_bills";
exports.endPointApiDeteleAllWasteBill="waste_bills/destroy_all"
exports.deleteUserApiMethod = "DELETE";
exports.getClientList = "/client_name_for_waste_bill";
exports.getBranchList = "/branch_name_for_waste_bill";
exports.createPickupRequest = "/pick_up_requests";
exports.getWasteCatagory = "/account_block/waste_type_categories";
exports.getImpactFactorEndpoint = "/waste_bills/calculate_impact_factors";
exports.getBranchEndpoint = "/account_block/account_branch_names";
exports.getBranchNames = "/account_block/branch_names";
exports.createWastebill = "/waste_bills";

exports.endPointGetCompanyList = "account_block/account_company_names";
exports.contentApiGetCompanyList = "application/json";
exports.methodApiGetCompanyList = "GET";
exports.pickupRequestListingClient = "/pick_up_requests";
exports.getCityNames = "/account_block/branch_names/cities"

exports.englishLanguage={
  wastebillSuccessfulAdded:"Your waste bill has been successfully added!",
  addNewWastebill:"Add New Waste Bill",
  wastebill:"Waste Bill",
  editWastebill:"Edit Waste Bill",
  edit:"Edit",
  wasteCatagory:"Waste Catagory",
  enterWasteCatagory:"Enter waste category",
  clientName:"Client Name",
  selectADate:"Select a Date...",
  enterClientName:"Enter client name",
  branchName:"Branch Name",
  enterBranchName:"Enter branch name",
  recyclableNetWeight:"Recyclable Net Weight",
  enterWeight:"Enter weight",
  reportDate:"Report Date",
  comment:"Comment",
  enterYourComments:"Enter your comments.",
  attachments:"Attachments",
  dragAndDropFilesOr:"Drag and Drop files or",
  browse:"Browse",
  max100KBFileSize:"Max 100 Kb File Size",
  impactFactors:"Impact Factors",
  treeSaved:"Tree Saved",
  kilowattEnergySaved:"Kilowatt of Energy Saved",
  co2EqReduction:"Co2 Reduction",
  landfillSpaceSaved:"Landfill Space Saved",
  waterSaved:"Water Saved",
  reductionPetroleumSaved:"Petroleum Saved",
  discardChanges:"Discard Changes",
  save:"Save",
  completed:"Completed",
  view:"View",
  yourPickupHasbeedSentSuccesfully:"Your pick up request has been successfully sent!",
  pickupRequest:"Pick Up Request",
  address:'Address',
  selectAddress:"Select Address",
  pickupDaySelection:"Pickup Day Selection",
  optional:"optional",
  editPickupRequest:"Edit Pick Up Request",
  selectBranchName:"Select Branch Name",
  submit:"Submit",
  rejected:"Rejected",
  accepted:"Accepted",
  pending:"Pending",
  reScheduled:"Re-scheduled",
  delete:"Delete",
  seeMore:"See more",
  cancel:"Cancel",
  areYouSureYouWantToDelete:"Are you sure you want to delete?",
  branch:"Branch",
  city:"City",
  pickupDate:"Pick Up Date",
  reason:"Reason",
  status:"Status",
  action:"Action",
  newPickupRequest:"New Pick Up Request",
  nothingToShowYet:"Nothing to show yet!",
  search:"Search",
  searchClient:"Search client",
  filters:"Filters",
  sort:"Sort",
  clearAll:"Clear All",
  timePeriod:"Time Period",
  custom:"Custom",
  apply:"Apply",
  pleaseContactNaqaaSolutions:"Please contact Naqaa Solutions for any queries or changes!",
  noSelections:"No selections",
  addNew:"Add New",
  impact:"Impact",
  name:"Name",
  amountOfWaste:"Amount of Waste",
  deleteAll:"Delete All",
  noResultsFound:"No Results Found!",
  goBack:"Go Back",
  today:"Today",
  thisWeek:"This Week",
  lastWeek:"Last Week",
  lastMonth:"Last Month",
  thisMonth:"This Month",
  newest:"Newest",
  ascendingA:"Ascending A",
  z:'Z',
  a:"A",
  descendingZ:"Descending Z",
  from:"From",
  From:"from",
  to:"To",
  clients:"Clients",
  showing:"Showing",
  customForm:"aa",
  currentContractPeriod:"Current Contract Period",
  last7Days:"Last 7 days",
  last30Days:"Last 30 days",
  oldest:"Oldest",
  barrels:"Barrels",
  kWh:"KWh",
  Kg : "KG",
  cubicMeter:"Cubic meter",
  trees:"Trees",
  gallons:"Gallons",
}

exports.arabicLanguage={
  wastebillSuccessfulAdded:"!تمت إضافة التقرير بنجاح",
  addNewWastebill:"إضافة تقرير جديد",
  editWastebill:"تعديل التقرير",
  selectADate:"إختيار التاريخ",
  wastebill:"التقارير",
  edit:"تعدّيل",
  barrels:"برميل",
  kWh:"كيلو واط",
  Kg : "كيلوجرام",
  cubicMeter:"متر مكعب",
  trees:"شجرة",
  gallons:"جالون",
  wasteCatagory:"الفئة",
  enterWasteCatagory:"أدخل الفئة",
  clientName:'اسم العميل',
  clients:"العملاء",
  search:"يبحث",
  enterClientName:"أدخل اسم العميل",
  branchName:"اسم الفرع",
  enterBranchName:'أدخل اسم الفرع',
  recyclableNetWeight:"الوزن الصافي",
  enterWeight:"أدخل الوزن",
  reportDate:"تاريخ التفريغ",
  comment:"ملاحظات",
  enterYourComments:"أدخل الملاحظات هنا",
  attachments:"مرفقات",
  dragAndDropFilesOr:"إسحب و أفلت الملف أو ",
  browse:"تصّفح",
  max100KBFileSize:"كيلو بايت هو الحد الأقصى لحجم الملف",
  last30Days:"اخر 30 ايام",
  impactFactors:"الأثر البيئي",
  last7Days:"اخر 7 ايام",
  treeSaved:"عدد الأشجار التي تم الحفاظ عليها",
  currentContractPeriod:"فترة العقد الحالية",
  kilowattEnergySaved:"تقليل الطاقة المستخدمة",
  co2EqReduction:"تقليل إنبعاثات ثاني أكسيد الكربون",
  landfillSpaceSaved:"مساحة أراضي المكبّات التي إنقاذها",
  waterSaved:"الماء الذي تم ترشيده",
  reductionPetroleumSaved:"تقليل إستخدام البترول",
  discardChanges:"تجاهل التغييرات",
  save:"حفظ",
  completed:"أكتمل التحمّيل",
  view:"معاينة",
  yourPickupHasbeedSentSuccesfully:"تم طلب التفريغ بنجاح!",
  pickupRequest:"طلب تفريغ",
  address:'العنوان',
  selectAddress:"إختيار الفرع",
  pickupDaySelection:"تاريخ التفريغ",
  optional:"إختياري",
  editPickupRequest:"تعدّيل طلب التفريغ",
  selectBranchName:"إختيار اسم الفرع",
  submit:"طلب",
  showing:"عرض",
  rejected:"مرفوض",
  accepted:"مقبول",
  pending:"قيد الإنتظار",
  reScheduled:"معاد جدولته",
  delete:"حذف",
  seeMore:"المزيد",
  cancel:"إلغاء",
  areYouSureYouWantToDelete:"هل أنت متأكد من حذف طلب التفريغ؟",
  branch:"الفرع",
  city:"المدينة",
  pickupDate:"تاريخ التفريغ",
  reason:"السبب",
  status:"الحالة",
  action:"الإجراء",
  newPickupRequest:"طلب تفريغ",
  nothingToShowYet:"لا توجد بيانات للآن",
  searchClient:"إبحث عن عميل",
  filters:'تصفية',
  sort:"ترتيب",
  clearAll:'مسح الكل',
  timePeriod:"حدود الوقت",
  custom:"تخصيص",
  apply:"تطبيق",
  pleaseContactNaqaaSolutions:"تواصل معنا للإستفسار أول التعديل",
  noSelections:"لم يتم الإختيار",
  addNew:"إضافة تقرير جديد",
  impact:"التأثير البيئي",
  name:"الاسم",
  amountOfWaste:"الكميّة",
  deleteAll:"حذف الكل",
  noResultsFound:"لا توجد نتائج",
  goBack:"العودة",
  today:"اليوم",
  thisWeek:"الأسبوع الحالي",
  lastWeek:"الأسبوع الماضي",
  lastMonth:"الشهر الماضي",
  thisMonth:"الشهر الحالي",
  newest:"الأحدث",
  ascendingA:"أ تصاعدي",
  z:'ي',
  a:"أ",
  oldest:"الأقدم",
  descendingZ:"ي تنازلي",
  from:"من",
  From:"من",
  to:"إلى",
}
// Customizable Area End