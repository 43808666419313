import React from "react";
import { Message } from "../../../framework/src/Message";
import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import { SQS } from "./WasteBillController.web";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  clientListigData: any[]
  loader: boolean
  searchQueryState: SQS,
  setSearchQueryState: (arg: SQS, callApi: boolean) => void
  onDelete: any,
  OnSelectItems: any,
  uncheckIcon: any,
  onHeaderCheck : any
 
  // Customizable Area End
}
// Customizable Area Start

// Customizable Area End
interface S {
  // Customizable Area Start
  menuIndex: number | null,
  selecetItem: any,
  headerCheck : boolean

  // Customizable Area End
}
interface SS { }

export default class WasteBillTableController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  deleteWasteListId: string = '';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);

    // Customizable Area Start


    this.state = {
      menuIndex: null,
      selecetItem: [],
      headerCheck : false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start
    console.log(this.props.uncheckIcon,'////////////////////')
    // Customizable Area End
  }

  // Customizable Area Start

    
  navigate(screenName : string, payload : any)
  {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    const raiseMessage = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), payload); 
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }

  edit(id : string)
  {
    this.props.navigation.history.push('AddNewWasteBill',{id,viewMode:false})
  }

  view(id : string)
  {
    this.props.navigation.history.push('AddNewWasteBill',{viewMode : true, id : id})
  }


  editClient = (clientId: string) => () => {
    this.props.navigation.history.push('AccountCreation', { id: clientId })
  }
  handleClickOutside(event: any) {
    if (event.target.className !== "client_listing_action_img") {
      this.setState({ menuIndex: null });
    }
    document.removeEventListener("click", this.handleClickOutside);
  }

  onActionClick = (e: any, index: number) => {
    if (this.state.menuIndex !== index) this.setState({ menuIndex: index });
    else this.setState({ menuIndex: null });
    document.addEventListener("click", this.handleClickOutside);

  };

  clearSearch()
  {
    this.props.setSearchQueryState({...this.props.searchQueryState, searchText : ""},true)
  }

  downPage = () => {
    if (this.props.searchQueryState.activePagiantionStep > 1) {
      this.props.setSearchQueryState({ ...this.props.searchQueryState, activePagiantionStep: this.props.searchQueryState.activePagiantionStep - 1 }, false)
    }
  }
  pageChange = (setPage: number) => () => {
    this.props.setSearchQueryState({ ...this.props.searchQueryState, page: setPage }, true)
  }
  upPage = () => {
    if (Math.ceil(this.props.searchQueryState.total_page / 5) > this.props.searchQueryState.activePagiantionStep) {
      this.props.setSearchQueryState({ ...this.props.searchQueryState, activePagiantionStep: this.props.searchQueryState.activePagiantionStep + 1 }, false)
    }
  }

  handleCheck = () =>
  {

    this.setState({headerCheck : !this.state.headerCheck},() => {
      let newIds : any = []
      if(this.state.headerCheck)
      {
      this.props.clientListigData.forEach((elem : any) => { 
          newIds.push(elem.attributes.id)
      })
    }
      this.props.onHeaderCheck(newIds)
      // this.props.onHeaderCheck(this.state.headerCheck)
    })
  }
}
