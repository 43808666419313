import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
export interface SQS {
  page: number,
  per_page: number,
  searchText: string,
  total_page: number
  total_count: number,
  activePagiantionStep: number,
  pick_up_date: string,
  clients: number[],
  branches: number[],
  citys: string[],
  custom_start_date: string,
  custom_end_date: string,
  sort_by: string
}
interface S {
  // Customizable Area Start
  search: any
  wasteIndex: number
  tabIndexString: any
  apiloader: boolean
  billdata: any
  showFilterModal: boolean
  showSortModal: boolean
  searchQueryState: SQS,
  selecetItem: any,
  companyList: any[],
  branchList: any[],
  showNoSelectionError : boolean;
  showSearchBar:boolean;
  // Customizable Area End
}
interface SS { }

export default class WasteBillController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getWasteListId: string = "";
  ListdebouncedSearch: any;
  deleteWasteListId: string = ""
  getListCompanyData: string = ""
  getBranchListData: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      search: "",
      wasteIndex: 0,
      tabIndexString: '0',
      apiloader: false,
      searchQueryState: {
        page: 1,
        per_page: 10,
        searchText: '',
        total_page: 1,
        total_count: 0,
        activePagiantionStep: 1,
        pick_up_date: '',
        clients: [],
        branches: [],
        citys: [],
        custom_start_date: '01-11-2023',
        custom_end_date: '',
        sort_by: 'created_at'

      },
      billdata: [],
      selecetItem: [],
      showSearchBar:false,
      showFilterModal: false,
      showSortModal: false,
      companyList: [],
      branchList: [],
      showNoSelectionError : false
    };

    // Customizable Area End
    this.ListdebouncedSearch = this.searchdebounce(this.fetchWasteTableData, 300);
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();

    // Customizable Area Start
    this.fetchWasteTableData();
    this.fetchCompanyData();
    this.fetchBranchData();
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (responseJson && responseJson.data) {
        console.log("response", responseJson)
        if (apiRequestCallId === this.getWasteListId) {

          this.setState({
            billdata: responseJson.data, apiloader: false,
            searchQueryState: { ...this.state.searchQueryState , total_count : responseJson.meta.pagy.count, total_page : responseJson.meta.pagy.pages}
          });


        }
      } else {
        this.handleApiResponse(responseJson, apiRequestCallId)

      }
      if (apiRequestCallId === this.deleteWasteListId) {
          if(this.state.billdata.length == 0 && this.state.searchQueryState.page > 1 )
          {
            this.setState({searchQueryState : {...this.state.searchQueryState,page : this.state.searchQueryState.page - 1}},
              () =>
              {
                this.fetchWasteTableData()
              })
          }
          else{
            this.fetchWasteTableData()
          }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  handleApiResponse = (responseJson: any, apiRequestCallId: any) => {
    if(responseJson && responseJson.branch_names && apiRequestCallId === this.getBranchListData) {
      let { branch_names } = responseJson;
      this.setState({ branchList: branch_names });
    }
    if (responseJson && responseJson.company_names && apiRequestCallId === this.getListCompanyData) {
      let { company_names } = responseJson
      console.log('company_names: ', company_names);
      this.setState({ companyList: company_names })
    } else if (responseJson && responseJson.errors) {
      this.handleErrorMessage(responseJson.errors, apiRequestCallId)
    }
  }
  OnSelectItems = (id: any) => {

this.setState({showNoSelectionError : false})
    this.setState((prevState: any) => {

      const isSelected = prevState.selecetItem.includes(id);


      const newSelectedIds = isSelected
        ? prevState.selecetItem.filter((selectedId: any) => selectedId !== id)
        : [...prevState.selecetItem, id];
      console.log('selected', newSelectedIds)
      return { selecetItem: newSelectedIds };

    });
  }


  setUncheckIcon= (list : any) =>
  {
    this.setState({showNoSelectionError : false})
   this.setState((prevState) => {
      return {selecetItem : list}})
  }

  onDelete = (id: any) => {

    let token = localStorage.getItem('token')

    let endPoint: string = `${webConfigJSON.endPointApiGetWasteBill}/${id}`;

    const filteredData = this.state.billdata?.filter(
      (e: any) => e.attributes?.id !== id
    );

    this.setState({ billdata: filteredData })

    const header = {
      "Content-Type": webConfigJSON.getUserListApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteWasteListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${endPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.deleteUserApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  onDeleteAll = () => {

    let token = localStorage.getItem('token')

    let endPoint: string = webConfigJSON.endPointApiDeteleAllWasteBill;



    let filteredData = this.state.billdata?.filter((item: any) => !this.state.selecetItem.includes(item.attributes?.id))

    this.setState({ billdata: filteredData })

    const header = {
      "Content-Type": webConfigJSON.getUserListApiContentType,
      token: token,
    };
    let raw = JSON.stringify({
      "waste_bill_ids": this.state.selecetItem
    });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteWasteListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${endPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      raw
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.deleteUserApiMethod
    );
    if(this.state.selecetItem.length > 0)
    {
      this.setState({selecetItem : []})
    runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    else{
      this.setState({showNoSelectionError : true})
    }

  }



  handleChangeSearch = (event: any) => {
    this.setState({
      searchQueryState: { ...this.state.searchQueryState, searchText: event.target?.value }
    })
    this.ListdebouncedSearch();
  }

   
  navigateTo(screenName : string)
  {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage),
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    const raiseMessage = new Message(
      getName(MessageEnum.NavigationPayLoadMessage),
    );
    raiseMessage.addData(getName(MessageEnum.SessionResponseData), { email:"Hello" }); 
    message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
    this.send(message);
  }
 

  searchdebounce = (func: any, delay: number) => {
    let timer: any;
    let self = this
    return function () {
      clearTimeout(timer);
      timer = setTimeout(() => {
        self.setState({ searchQueryState: { ...self.state.searchQueryState } }, () => func())
      }, delay);
    };
  }
  fetchCompanyData = () => {
    let usertoken = localStorage.getItem('token')
    const header = {
      "Content-Type": webConfigJSON.contentApiGetCompanyList,
      token: usertoken,
    };
    const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage));
    this.getListCompanyData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.endPointGetCompanyList
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.methodApiGetCompanyList
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  fetchBranchData = () => {
    let usertoken = localStorage.getItem('token')
    const header = {
      "Content-Type": webConfigJSON.contentApiGetCompanyList,
      token: usertoken,
    };
    const requestMessage = new Message( getName(MessageEnum.RestAPIRequestMessage));
    this.getBranchListData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.getBranchList
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.methodApiGetCompanyList
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleErrorMessage = (errors: any, apiRequestCallId: any) => {
    if (errors[0].token) {
      localStorage.clear()
      this.props.navigation.navigate('EmailAccountLoginBlock')
    }
    if (errors.includes('Accounts data does not exist') || (errors.includes("Waste Bills data does not exist")) && apiRequestCallId === this.getWasteListId) {
      this.setState({
        billdata: [],
        apiloader: false,
        searchQueryState: {
          ...this.state.searchQueryState, page: 1,
          total_page: 1,
          total_count: 0,
          activePagiantionStep: 1
        }
      });
    }
  }

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ wasteIndex: newValue })
  }
  showFilterModal = () => {
    this.setState({ showFilterModal: !this.state.showFilterModal })
  }

  fetchWasteTableData = () => {
    this.setState({ apiloader: true })
    let token = localStorage.getItem('token')
    let { searchQueryState } = this.state
    let payloadapi = { searchText: 'search_query', pick_up_date: 'filters[pick_up_date]', sort_by: 'sort_by', clients: 'filters[client_ids][]', branches: 'filters[branch_name_ids][]' }
    let endPoint: string = `${webConfigJSON.endPointApiGetWasteBill}?page=${searchQueryState.page}&per_page=${searchQueryState.per_page}`
    // @ts-ignore: Unreachable code error

    Object.keys(payloadapi).forEach((payloadtype) => {
      // @ts-ignore

      if (searchQueryState[payloadtype]) {
        if (payloadtype === 'pick_up_date' && searchQueryState.pick_up_date === 'custom_date') {
          endPoint = `${endPoint}&filters[pick_up_date][custom_date][start_date]=${searchQueryState.custom_start_date}&filters[pick_up_date][custom_date][end_date]=${searchQueryState.custom_end_date}`
        } else {
          // @ts-ignore
          if (searchQueryState[payloadtype].length) {
            // @ts-ignore
            if (Array.isArray(searchQueryState[payloadtype])) {
              // @ts-ignore
              searchQueryState[payloadtype].map((value: any) => {
                // @ts-ignore
                endPoint = `${endPoint}&${payloadapi[payloadtype]}=${value}`
              })
            }
            // @ts-ignore
            endPoint = `${endPoint}&${payloadapi[payloadtype]}=${searchQueryState[payloadtype]}`
          }
        }
      }
    })

    const header = {
      "Content-Type": webConfigJSON.getUserListApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getWasteListId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${endPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.getUserListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }


  setSearchQueryState = (queryState: SQS, callApi: boolean) => {
    this.setState({ searchQueryState: { ...queryState } }, () => {
      if (callApi) {
        this.fetchWasteTableData()
      }
      this.handleClickAwaySort()
    })
  }

  handleClickAwayFilter = () => {
    this.setState({ showFilterModal: false })
  }

  handleClickAwaySort = () => {
    this.setState({ showSortModal: false })
  }

  showSortModal = () => {
    this.setState({ showSortModal: !this.state.showSortModal })
  }

  showSearchBar=()=>{
    this.setState({showSearchBar:!this.state.showSearchBar})
  }
  // Customizable Area End
}
