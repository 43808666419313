import React from "react";

// Customizable Area Start

import { Box,Button } from "@material-ui/core";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { editIcon } from "./assets";
import Navbar from "../../settings2/src/Navbar.web";
import Strings from "../../../components/src/Strings copy";
// Customizable Area End

import TermsConditionsEditController, {
  Props,
  configJSON,
} from "./TermsConditionsEditController";

export default class TermsConditionsEdit extends TermsConditionsEditController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  heading : string = 'Terms & Conditions' 
 
  // Customizable Area End

  render() {
    // Customizable Area Start 
    return (
    <div className = "terms-and-condition-container">
    <div className="main-wrapper" style={{display: 'flex',
   background : "#F5F5F5"}}>
      <NavigationMenu navigation={this.props.navigation} id={"7"} />
      <div style={{background : "#F5F5F5",flex: 1}}>
        <Navbar navigation={this.props.navigation} id={""} />
      <Box sx={
        {
          display : 'flex',
          flexDirection : 'column',
          flexWrap : 'wrap',
          margin: '2.75rem 2rem 2rem 2rem',
                 
        }
      }>

        <Box sx={{ 
          display : 'flex',
          flexDirection : 'row',
          justifyContent : 'space-between',
          alignItems : 'center',
          marginBottom : '2.25rem '
        }}>
          <div style={{display : 'flex',flexDirection : 'row', alignItems : 'center'}}>
            {/* <span style={{ marginRight : '1.37rem'}}> <KeyboardBackspaceIcon/></span> */}
            <div className="edit-header">
              {Strings.tandcEditHeading}
            </div>
            </div>

            <div>
              { 
              
              this.state.edit ? null : 
             <button className="edit-btn" id="edit-btn" onClick={this.editClick} style={{paddingRight : '1rem'}}>
              <span className="inner-padding">
              <i>
                <img src={editIcon} style={{height:"24px", width : "24px"}} />
              </i>
              <span className="edit-text"> {Strings.edit} </span>
              </span>
             </button>
             
             }
            </div>
        </Box>

             { this.state.edit ?
             
        <textarea className="edit-terms" id="mdEditor" placeholder="Type text here..." value = { this.state.tempTerms } onChange={(event) => this.editText(event?.target.value)}/> :

        <Box sx={{
          display : 'flex',
          flexWrap: 'Wrap',
          flexShrink : '1',
          height : 'auto',
          fontSize : '0.875rem',
          fontWeight : 400,
          lineHeight : '1.5rem',
          whiteSpace : 'pre-wrap',
          fontFamily: 'Barlow semi condensed'
        }}>

                {this.state.termsConds}
        </Box>

      }

      </Box>

      { this.state.edit ?
      <div className="edit-terms-footer-container"
      >

          <button
            data-testid={"discardButton"}
            className="add-button"
            onClick={this.discardChanges}
          >
            <p className="add-button-text">{Strings.discard}</p>
          </button>
          <button
            data-testid={"saveButton"}            
            id="btnSendTermsConds" style={containedBtn} className={`add-button button-fill ${this.state?.tempTerms?.trim() ? '' : 'disable'}`} onClick={this.sendTermsConds}
          >
            <p className="add-button-text">{Strings.save}</p>
          </button>
       

      </div> : null
       }
      </div>
      </div>
      </div>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const outlinedBtn = { 
  color : '#32569A', border: 'solid 0.06rem #32569A', marginRight: '2rem',
  borderRadius : '0.5rem',
  height:'2.75rem',
  Width : '9rem',
  fontFamily : 'Barlow Semi Condensed'
}

const containedBtn = {
  marginLeft : '2rem'
}
// Customizable Area End
